<template>
    <v-dialog v-model="shows" max-width="1660" persistent>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }" tile class="d-flex flex-column" max-height="90vh" style="overflow: hidden">
            <v-card-title>
                <template v-if="isCreate">회원 생성</template>
                <template v-else>회원 수정</template>
                <v-spacer />
                <v-btn absolute right text icon @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="py-6" style="flex: 0 1 100%; overflow-y: auto">
                <v-row class="ma-n2">
                    <v-col cols="12" md="6" xl="7" class="pa-2">
                        <v-row class="ma-n2">
                            <v-col cols="12" class="pa-2">
                                <form-base v-model="form" @input="emit" />
                            </v-col>

                            <v-col cols="12" md="6" class="pa-2">
                                <form-files v-model="form" @input="emit" />
                            </v-col>

                            <v-col cols="12" md="6" class="pa-2">
                                <v-row class="ma-n2">
                                    <v-col cols="12" class="pa-2">
                                        <form-facility v-model="form" @input="emit" />
                                    </v-col>
                                    <v-col cols="12" class="pa-2">
                                        <form-memo v-model="form" @input="emit" />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12" md="6" xl="5" class="pa-2">
                        <form-equipments v-model="form" @input="emit" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-col cols="auto" class="py-0" style="min-width: 140px">
                    <!-- <v-switch v-model="form.isActive" v-bind="switchAttrs">
                        <template #label>
                            <span class="subtitle-2">
                                <template v-if="form.isActive">사용</template>
                                <template v-else>미사용</template>
                            </span>
                        </template>
                    </v-switch> -->
                </v-col>
                <v-spacer />
                <v-btn large color="primary" v-bind="{ loading }" @click="save"> 저장하기 </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { inputAttrs, switchAttrs, initUserOrganization, RENTAL_SUBJECT_TYPES, USER_TYPES, attrs_input__verticalTable } from "@/assets/variables";

import FormBase from "./form-base.vue";
import FormMemo from "./form-memo.vue";
import FormFiles from "./form-files.vue";
import FormFacility from "./form-facility.vue";
import FormEquipments from "./form-equipments.vue";

export default {
    components: {
        FormBase,
        FormMemo,
        FormFiles,
        FormFacility,
        FormEquipments,
    },
    props: {
        value: { type: Object, default: initUserOrganization },
    },
    data: () => ({
        shows: false,
        loading: false,

        form: initUserOrganization(),

        USER_TYPES,
        RENTAL_SUBJECT_TYPES,

        inputAttrs,
        switchAttrs,
        attrs_input__verticalTable,

        JSON,
    }),
    computed: {
        _user() {
            return this.value?._id;
        },
        isCreate() {
            return !this._user;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (this.isCreate) {
                    this.form = initUserOrganization();
                } else {
                    let { user } = await api.console.users.get({ _id: this._user });

                    this.form = initUserOrganization(user);
                }
            } finally {
                this.loading = false;
            }
        },

        async emit() {
            this.form = initUserOrganization(this.form);
        },

        validate() {},

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.validate();

                let { _businessRegistration, businessRegistration, blacksmith, ...form } = this.form;

                // POST/PUT user
                let { post, put } = api.console.users;
                let { user } = await (this.isCreate ? post(form) : put(form));
                const { _id: _user } = user || {};

                // DELETE/POST businessRegistration
                if (businessRegistration instanceof File) businessRegistration = (await api.console.common.files.post({ path: "business-registration", _user }, businessRegistration))?.file;
                _businessRegistration = businessRegistration?._id || null;

                // DELETE/POST equipmentUseConsent
                let { _equipmentUseConsent, equipmentUseConsent } = blacksmith || {};
                if (equipmentUseConsent instanceof File) equipmentUseConsent = (await api.console.common.files.post({ path: "equipment-use-consent", _user }, equipmentUseConsent))?.file;
                _equipmentUseConsent = equipmentUseConsent?._id || null;
                blacksmith._equipmentUseConsent = _equipmentUseConsent;
                delete blacksmith.equipmentUseConsent;

                // DELETE/POST insurancePolicies
                let { insurance: { _policies = [], policies = [] } = {} } = blacksmith || {};

                policies = await Promise.all(policies.map(async (policy, index) => (policy instanceof File ? (await api.console.common.files.post({ path: "insurance-policies", _user, index }, policy))?.file : policy)));
                _policies = policies?.map?.((item) => item?._id)?.filter?.((_id) => _id) || [];
                blacksmith.insurance._policies = _policies;
                delete blacksmith.insurance.policies;

                // // PUT user
                user = (await put({ _id: _user, _businessRegistration, blacksmith }))?.user;

                alert("저장되었습니다");
                this.$emit("input", user);
                this.shows = false;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
