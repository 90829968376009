<template>
    <v-text-field v-bind="$attrs" class="file-chips-field" readonly>
        <template #append>
            <file-chips-input v-model="files" @input="emit" />
        </template>
    </v-text-field>
</template>

<script>
import FileChipsInput from "./file-chips-input.vue";

export default {
    components: {
        FileChipsInput,
    },
    props: {
        value: { type: Array, default: () => [] },
    },
    data: () => ({
        files: [],
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.files = this.value;
        },
        emit() {
            this.$emit("input", this.files);
        },
    },
};
</script>

<style lang="scss" scoped>
.file-chips-field {
    ::v-deep {
        .v-input__slot {
            cursor: initial;
        }
        .v-text-field__slot {
            position: absolute !important;
            & > input {
                cursor: initial;
            }
        }
        .v-input__append-inner {
            margin: 0 !important;
            align-self: center;
        }
    }
}
</style>