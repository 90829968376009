var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "880",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', _vm._b({
    staticClass: "d-flex flex-column",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "tile": "",
      "max-height": "90vh"
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_vm.isCreate ? [_vm._v("공지사항 생성")] : [_vm._v("공지사항 수정")], _c('v-spacer'), _c('v-btn', {
    attrs: {
      "absolute": "",
      "right": "",
      "text": "",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 2), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-6",
    staticStyle: {
      "flex": "0 1 100%",
      "overflow-y": "auto"
    }
  }, [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "제목"
    },
    model: {
      value: _vm.form.subject,
      callback: function ($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-datetime-field', _vm._b({
    attrs: {
      "label": "생성일시"
    },
    model: {
      value: _vm.form.createdAt,
      callback: function ($$v) {
        _vm.$set(_vm.form, "createdAt", $$v);
      },
      expression: "form.createdAt"
    }
  }, 'v-datetime-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('file-chips-field', _vm._b({
    attrs: {
      "label": "첨부파일"
    },
    model: {
      value: _vm.form.files,
      callback: function ($$v) {
        _vm.$set(_vm.form, "files", $$v);
      },
      expression: "form.files"
    }
  }, 'file-chips-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('naver-smarteditor', {
    attrs: {
      "rows": "10"
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-col', {
    staticClass: "py-0",
    staticStyle: {
      "min-width": "140px"
    },
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', _vm._b({
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('span', {
          staticClass: "subtitle-2"
        }, [_vm._v(" 상단고정 ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.isNotice,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isNotice", $$v);
      },
      expression: "form.isNotice"
    }
  }, 'v-switch', _vm.switchAttrs, false))], 1), _c('v-col', {
    staticClass: "py-0",
    staticStyle: {
      "min-width": "140px"
    },
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', _vm._b({
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('span', {
          staticClass: "subtitle-2"
        }, [_vm.form.shows ? [_vm._v("노출")] : [_vm._v("미노출")]], 2)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.shows,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shows", $$v);
      },
      expression: "form.shows"
    }
  }, 'v-switch', _vm.switchAttrs, false))], 1), _c('v-spacer'), _c('v-btn', _vm._b({
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v(" 저장하기 ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }