<template>
    <view-section-card title="장비사용">
        <autocomplete-rental-subject v-model="form.blacksmith._equipments__rentable" label="사용가능 장비" :params="{ type: RENTAL_SUBJECT_TYPES.EQUIPMENTS.value }" :headers="{ sort: JSON.stringify({ name: 1 }) }" multiple dense :menu-props="{ maxHeight: '90vh' }" v-bind="attrs_input__verticalTable" @input="emit">
            <template #selection="{ parent, item, attrs, itemText }">
                <v-chip outlined label close v-bind="attrs" @click:close="parent.selectItem(item)" :key="item._id">{{ itemText(item) }}</v-chip>
            </template>
        </autocomplete-rental-subject>
    </view-section-card>
</template>

<script>
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

import { initUserOrganization, attrs_input__verticalTable, RENTAL_SUBJECT_TYPES } from "@/assets/variables";
import AutocompleteRentalSubject from "@/components/dumb/autocomplete-rental-subject.vue";

export default {
    components: {
        ViewSectionCard,
        AutocompleteRentalSubject,
    },
    props: {
        value: { type: Object, default: initUserOrganization },
    },
    data: () => ({
        form: initUserOrganization(),

        RENTAL_SUBJECT_TYPES,

        attrs_input__verticalTable,

        JSON,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initUserOrganization(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style>
</style>