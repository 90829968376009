<template>
    <view-section-card title="사용자메모">
        <v-textarea v-model="form.note" rows="4" auto-grow v-bind="attrs_input__verticalTable" @input="emit" />
    </view-section-card>
</template>

<script>
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

import { initUserOrganization, attrs_input__verticalTable } from "@/assets/variables";

export default {
    components: {
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: initUserOrganization },
    },
    data: () => ({
        form: initUserOrganization(),

        attrs_input__verticalTable,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initUserOrganization(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style>
</style>