var render = function render(){
  var _vm$form$businessRegi, _vm$form$businessRegi2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', {
    attrs: {
      "title": "기본정보"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        var _vm$form, _vm$form$createdAt, _vm$form$createdAt$to;
        return [_c('span', {
          staticClass: "caption"
        }, [_vm._v(" 가입일시: " + _vm._s((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : (_vm$form$createdAt = _vm$form.createdAt) === null || _vm$form$createdAt === void 0 ? void 0 : (_vm$form$createdAt$to = _vm$form$createdAt.toDateTime) === null || _vm$form$createdAt$to === void 0 ? void 0 : _vm$form$createdAt$to.call(_vm$form$createdAt)) + " ")])];
      },
      proxy: true
    }])
  }, [_c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": "4"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "아이디",
      "readonly": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.username,
      callback: function ($$v) {
        _vm.$set(_vm.form, "username", $$v);
      },
      expression: "form.username"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": "4"
    }
  }, [_c('v-password-field', _vm._b({
    attrs: {
      "label": "비밀번호",
      "readonly": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }, 'v-password-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": "4"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "회원유형",
      "items": _vm.types
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, 'v-select', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": "4"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "회원명"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": "4"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "연락처"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": "4"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "이메일"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _vm.form.type == _vm.USER_TYPES.ORGANIZATION ? [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": ""
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "기업명"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.companyName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "companyName", $$v);
      },
      expression: "form.companyName"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": ""
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "사업자등록번호"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.businessNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "businessNumber", $$v);
      },
      expression: "form.businessNumber"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "xl": "7"
    }
  }, [_c('v-row', {
    staticClass: "ma-n2",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-2"
  }, [_c('v-file-input', _vm._b({
    attrs: {
      "label": "사업자등록증",
      "prepend-icon": "",
      "prepend-inner-icon": "mdi-file"
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.form.businessRegistration,
      callback: function ($$v) {
        _vm.$set(_vm.form, "businessRegistration", $$v);
      },
      expression: "form.businessRegistration"
    }
  }, 'v-file-input', _vm.inputAttrs, false))], 1), (_vm$form$businessRegi = _vm.form.businessRegistration) !== null && _vm$form$businessRegi !== void 0 && _vm$form$businessRegi.src ? _c('v-col', {
    staticClass: "py-2 pr-2 pl-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "icon": "",
      "tile": "",
      "target": "_blank",
      "href": (_vm$form$businessRegi2 = _vm.form.businessRegistration) === null || _vm$form$businessRegi2 === void 0 ? void 0 : _vm$form$businessRegi2.src,
      "download": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-file-download")])], 1)], 1) : _vm._e()], 1)], 1)] : _vm._e()], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }