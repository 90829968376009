<template>
    <view-section-card title="장비이용동의서 및 보험정보">
        <v-card-text>
            <v-row class="ma-n2">
                <v-col cols="12" class="pa-2">
                    <v-row align="center" class="ma-n2">
                        <v-col class="pa-2">
                            <v-file-input v-model="form.blacksmith.equipmentUseConsent" label="장비이용동의서" prepend-icon="" prepend-inner-icon="mdi-file" v-bind="inputAttrs" @change="emit" />
                        </v-col>
                        <v-col v-if="form.blacksmith.equipmentUseConsent?.src" cols="auto" class="py-2 pr-2 pl-0">
                            <v-btn text icon tile target="_blank" :href="form.blacksmith.equipmentUseConsent?.src" download> <v-icon>mdi-file-download</v-icon> </v-btn>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" class="pa-2"> <v-divider /> </v-col>

                <v-col cols="12" class="pa-2">
                    <v-text-field v-model="form.blacksmith.insurance.expiresAt" label="보험만기일" type="date" max="2999-12-31" clearable v-bind="inputAttrs" @input="emit" />
                </v-col>
                <v-col cols="12" class="pa-2">
                    <v-row align="center" class="ma-n2">
                        <v-col class="pa-2">
                            <v-file-input v-model="form.blacksmith.insurance.policies[0]" label="보험증서" prepend-icon="" prepend-inner-icon="mdi-file" v-bind="inputAttrs" @change="emit" />
                        </v-col>
                        <v-col cols="auto" class="py-2 pr-2 pl-0" v-if="form.blacksmith.insurance.policies[0]?.src">
                            <v-btn text icon tile target="_blank" :href="form.blacksmith.insurance.policies[0]?.src" download> <v-icon>mdi-file-download</v-icon> </v-btn>
                        </v-col>
                    </v-row>
                </v-col>

                <v-expand-transition>
                    <v-col cols="12" class="pa-0" v-show="!!form.blacksmith.insurance.policies[0]">
                        <div class="pa-2">
                            <v-row align="center" class="ma-n2">
                                <v-col class="pa-2">
                                    <v-file-input v-model="form.blacksmith.insurance.policies[1]" label="보험증서" prepend-icon="" prepend-inner-icon="mdi-file" v-bind="inputAttrs" @change="emit" />
                                </v-col>
                                <v-col cols="auto" class="py-2 pr-2 pl-0" v-if="form.blacksmith.insurance.policies[1]?.src">
                                    <v-btn text icon tile target="_blank" :href="form.blacksmith.insurance.policies[1]?.src" download> <v-icon>mdi-file-download</v-icon> </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-expand-transition>

                <v-expand-transition>
                    <v-col cols="12" class="pa-0" v-show="!!form.blacksmith.insurance.policies[1]">
                        <div class="pa-2">
                            <v-row align="center" class="ma-n2">
                                <v-col class="pa-2">
                                    <v-file-input v-model="form.blacksmith.insurance.policies[2]" label="보험증서" prepend-icon="" prepend-inner-icon="mdi-file" v-bind="inputAttrs" @change="emit" />
                                </v-col>
                                <v-col cols="auto" class="py-2 pr-2 pl-0" v-if="form.blacksmith.insurance.policies[2]?.src">
                                    <v-btn text icon tile target="_blank" :href="form.blacksmith.insurance.policies[2]?.src" download> <v-icon>mdi-file-download</v-icon> </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-expand-transition>
            </v-row>
        </v-card-text>
    </view-section-card>
</template>

<script>
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

import { initUserOrganization, inputAttrs } from "@/assets/variables";

export default {
    components: {
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: initUserOrganization },
    },
    data: () => ({
        form: initUserOrganization(),

        inputAttrs,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initUserOrganization(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style>
</style>