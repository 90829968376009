<template>
    <v-layout justify-center>
        <v-responsive width="100%" height="100%" class="mx-n3 px-3">
            <list-heading title="공지사항">
                <template #add-button>
                    <notification-form @input="search">
                        <template #activator="{ attrs, on }">
                            <v-btn text icon tile color="primary" title="공지사항 생성" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </notification-form>
                </template>
            </list-heading>

            <v-data-table v-bind="{ headers, items, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1" class="v-sheet--outlined">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value) : undefined" v-html="header.formatter.bind(item)(value)" /> </template>

                <template #[`item.notification`]="{ item }">
                    <v-row @click="$set(item, 'show', !item.show)">
                        <v-col cols="auto" class="text-start">{{ item.subject }}</v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto">
                            <v-icon v-if="!item.show">mdi-chevron-down</v-icon>
                            <v-icon v-else>mdi-chevron-up</v-icon>
                        </v-col>
                    </v-row>
                    <v-expand-transition>
                        <v-row v-show="item.show">
                            <v-col cols="12" class="text-start">
                                <p v-html="item.content?.replace?.(/\n/g, '<br>')"></p>
                            </v-col>
                        </v-row>
                    </v-expand-transition>
                </template>

                <template #[`item.isNotice`]="{ item }">
                    <v-switch v-model="item.isNotice" class="d-inline-block mr-n4" v-bind="{ ...switchAttrs, loading }" @change="(isNotice) => update({ _id: item._id, isNotice })" />
                </template>

                <template #[`item.shows`]="{ item }">
                    <v-switch v-model="item.shows" class="d-inline-block mr-n4" v-bind="{ ...switchAttrs, loading }" @change="(shows) => update({ _id: item._id, shows })" />
                </template>

                <template #[`item.actions`]="{ item }">
                    <notification-form :value="item" @input="(item) => updateItem(item)">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon v-bind="attrs" v-on="on">
                                <v-icon small> mdi-pencil </v-icon>
                            </v-btn>
                        </template>
                    </notification-form>

                    <v-btn small text icon @click="remove(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>

                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="20" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
                </template>
            </v-data-table>

            <v-fade-transition>
                <v-overlay v-show="loading">
                    <v-progress-circular indeterminate size="100" width="5" />
                </v-overlay>
            </v-fade-transition>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { switchAttrs } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import FileChipsField from "@/components/console/dumb/file-chips-field.vue";
import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

import NotificationForm from "@/components/console/center/notifications/notification-form.vue";

const headers = [
    { text: "#", value: "index", width: +80 },
    { text: "공지사항", value: "notification" },
    { text: "작성일자", value: "createdAt", formatter: (value) => value?.toDate?.() || value || "-", width: 100 },
    { text: "상단고정", value: "isNotice", width: +90 },
    { text: "노출여부", value: "shows", width: +90 },
    { text: "", value: "actions", width: 100 },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, align: item.align ?? "start", class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "") + " caption line-height-1-5 white-space-pre-line" }));

export default {
    components: {
        ListHeading,
        FileChipsField,
        VDatetimeField,
        NaverSmarteditor,

        NotificationForm,
    },
    data: () => ({
        notifications: [],

        limit: 10,
        summary: { totalCount: 0 },

        headers,

        switchAttrs,

        loading: false,
    }),
    computed: {
        items() {
            return this.notifications.map((item, index) => ({ ...item, index: (this.page - 1) * this.limit + index + 1 }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.page;
            delete query.sort;

            return { ...query };
        },
    },
    created() {
        this.init();
    },
    watch: {
        page() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;
            try {
                const { skip, limit, params } = this;
                let { summary, notifications } = await api.console.center.notifications.gets({
                    headers: { skip, limit },
                    params,
                });

                this.notifications = notifications;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },

        async update(item) {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { notification } = await api.console.center.notifications.put(item);
                this.updateItem(notification);
                this.loading = false;
                this.search();
            } finally {
                if (this.loading) this.loading = false;
            }
        },

        async updateItem(item) {
            const index = this.notifications.findIndex(({ _id }) => _id == item._id);
            this.notifications.splice(index, 1, item);
            if (index == -1) this.search();
            else this.notifications.splice(index, 1, item);
        },

        async remove(item) {
            const go = confirm(`해당 공지사항을 삭제하시겠습니까?\r\n공지사항: ${item?.subject}`);
            if (!go) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                await api.console.center.notifications.delete(item);

                this.notifications = this.notifications.filter(({ _id }) => _id !== item._id);
                this.summary.totalCount -= 1;

                alert(`해당 공지사힝이 삭제되었습니다.`);
                this.loading = false;
                await this.search();
            } finally {
                if (this.loading) this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        display: flex;
        align-items: center;
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
