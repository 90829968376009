<template>
    <view-section-card title="시설사용" hideDivider>
        <template #actions> <v-switch v-model="form.blacksmith.facility.isActive" v-bind="switchAttrs" class="mr-n3" @change="emit" /> </template>
    </view-section-card>
</template>

<script>
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

import { initUserOrganization, switchAttrs } from "@/assets/variables";

export default {
    components: {
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: initUserOrganization },
    },
    data: () => ({
        form: initUserOrganization(),

        switchAttrs,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initUserOrganization(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style>
</style>