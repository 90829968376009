<template>
    <v-layout justify-center>
        <v-responsive width="100%" height="100%" class="mx-n3 px-3">
            <list-heading v-model="showsSearch" title="회원 목록" showsFilterButton />

            <user-list-search v-bind="{ showsSearch }" flat outlined />

            <v-row class="ma-n3">
                <v-col cols="auto">
                    <user-list-edit-equipments v-bind="{ selected, params }" v-on="{ search }">
                        <template #activator="{ attrs, on }">
                            <v-card outlined style="overflow: hidden" v-bind="attrs" v-on="on">
                                <v-btn text tile color="primary">
                                    <v-icon class="mr-2">mdi-pencil</v-icon>
                                    <span>사용가능장비 일괄수정</span>
                                </v-btn>
                            </v-card>
                        </template>
                    </user-list-edit-equipments>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <v-card outlined style="overflow: hidden" @click="excel">
                        <v-btn text tile color="green">
                            <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
                            <span>엑셀 다운로드</span>
                        </v-btn>
                    </v-card>
                </v-col>
            </v-row>

            <v-data-table v-model="selected" v-bind="{ headers, items, loading }" show-select disable-sort disable-pagination hide-default-footer :items-per-page="-1" class="v-sheet--outlined mt-3" style="overflow: hidden">
                <template #[`header.data-table-select`]="{ props, on }">
                    <v-simple-checkbox v-bind="props" v-on="on" class="mr-n2" />
                </template>

                <template #[`item.data-table-select`]="{ select, isSelected }">
                    <v-simple-checkbox :value="isSelected" class="mr-n2" @click="select(!isSelected)" />
                </template>

                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value) : undefined" v-html="header.formatter.bind(item)(value)" /> </template>

                <template #[`item.note`]="{ item, value }">
                    <edit-note :value="item" v-on="{ update }"> {{ value || "-" }} </edit-note>
                </template>

                <template #[`item.blacksmith.insurance.policies`]="{ value }">
                    <template v-if="value?.length">
                        <image-tiles :images="(value || []).map(({ url }) => url)" tilesOnly cols="12" />
                    </template>
                    <template v-else> - </template>
                </template>

                <template #[`item.blacksmith.equipmentUseConsent`]="{ value }">
                    <template v-if="value">
                        <image-popup :src="value?.src" rounded outlined />
                    </template>
                    <template v-else> - </template>
                </template>

                <template #[`item.blacksmith.equipments__rentable`]="{ item, value, header }">
                    <user-item-equipments v-bind="{ item }">
                        <template #activator="{ attrs, on }">
                            <v-card flat tile :width="header.width ?? 200" color="transparent" class="px-4 py-3 caption text-truncate" :title="header.formatter(value)" v-bind="attrs" v-on="on"> {{ header.formatter(value).replaceAll("\r\n", " / ") }} </v-card>
                        </template>
                    </user-item-equipments>
                </template>

                <template #[`item.blacksmith.facility.isActive`]="{ item }">
                    <v-switch v-model="item.blacksmith.facility.isActive" class="d-inline-block mr-n4" v-bind="switchAttrs" @change="(isActive) => update({ _id: item._id, blacksmith: { ...item.blacksmith, facility: { ...item.blacksmith.facility, isActive } } })" />
                </template>

                <template #[`item.actions`]="{ item }">
                    <user-form :value="item" @input="search">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon tile v-bind="attrs" v-on="on">
                                <v-icon small> mdi-pencil </v-icon>
                            </v-btn>
                        </template>
                    </user-form>
                    <v-btn small text icon tile @click="remove(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>

                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="20" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
                </template>
            </v-data-table>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { switchAttrs, USER_TYPES } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";

import UserForm from "@/components/console/user/form/user-form.vue";
import EditNote from "@/components/console/dumb/edit/edit-note.vue";
import ImagePopup from "@/components/console/dumb/image-popup.vue";
import ImageTiles from "@/components/dumb/image-tiles/image-tiles.vue";
import UserListSearch from "@/components/console/user/list/user-list-search.vue";
import UserItemEquipments from "@/components/console/user/item/user-item-equipments.vue";
import UserListEditEquipments from "@/components/console/user/list/edit/user-list-edit-equipments.vue";

const headers = [
    { text: "#", value: "index", width: +60, align: "center" },
    { text: "회원유형", value: "type", formatter: (value) => ({ [USER_TYPES.INDIVIDUAL]: "개인회원", [USER_TYPES.ORGANIZATION]: "기업회원" }[value] || "관리자"), width: +90, align: "center" },
    { text: "기업명", value: "companyName", cellClass: "text-truncate max-width-0", withTitle: true },
    { text: "회원명", value: "name", cellClass: "text-truncate max-width-0", withTitle: true },
    { text: "아이디", value: "username", cellClass: "text-truncate max-width-0", withTitle: true },
    { text: "연락처", value: "phone", formatter: (value) => value?.phoneNumberFormat?.() || value || "-", width: 120 },
    { text: "이메일", value: "email", cellClass: "text-truncate max-width-0", withTitle: true },
    { text: "가입일시", value: "createdAt", formatter: (value, item, isExcel = false) => [...(value?.toDateTime?.()?.split?.(" ") || "")]?.join?.(isExcel ? " " : "\r\n") || value || "-", width: 100 },
    { text: "사용가능장비", value: "blacksmith.equipments__rentable", formatter: (value = []) => [...value.map(({ name }) => name?.trim?.() || name).sort()].join("\r\n") || "-", cellClass: "pa-0", width: 180 },
    { width: 200, text: "메모", value: "note" },
    { text: "보험증서", value: "blacksmith.insurance.policies", class: "pa-0", cellClass: "pa-1", width: +60, align: "center", excel: (items) => [...(items || []).map((item) => item?.url).filter((url) => url)].join("\r\n") || "-" },
    { text: "보험만기일", value: "blacksmith.insurance.expiresAt", formatter: (value) => value?.toDateTime?.()?.split?.(" ")?.join?.("\n") || value || "-", class: "pa-0", cellClass: "pa-1", width: 100, align: "center" },
    { text: "장비이용\r\n동의서", value: "blacksmith.equipmentUseConsent", class: "pa-0", cellClass: "pa-1", width: +60, align: "center", excel: (value) => value?.url || "-" },
    { text: "시설사용", value: "blacksmith.facility.isActive", width: +90, align: "center", formatter: (value) => ({ [null]: "-", [true]: "사용", [false]: "미사용" }[value]) },
    { text: "", value: "actions", width: +88 },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, align: item.align ?? "start", class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "") + " caption line-height-1-5 white-space-pre-line" }));

export default {
    components: {
        ListHeading,

        UserForm,
        EditNote,
        ImagePopup,
        ImageTiles,
        UserListSearch,
        UserItemEquipments,
        UserListEditEquipments,
    },
    data: () => ({
        users: [],
        selected: [],

        limit: 10,
        summary: { totalCount: 0 },

        headers,
        switchAttrs,

        loading: false,
        showsSearch: true,
    }),
    computed: {
        items() {
            return this.users.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            return { ...query };
        },
        toResetSelected() {
            return this.$route.query.page == undefined;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        toResetSelected() {
            this.selected = [];
        },
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.selected = [];
            this.search();
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, users } = await api.console.users.gets({
                    headers: { skip, limit },
                    params,
                });

                this.users = users;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },

        async update(item) {
            const { user } = await api.console.users.put(item);
            this.updateItem(user);
        },

        async remove(user) {
            const go = confirm(`회원 (ID: ${user?.username})를 삭제하시겠습니까?`);
            if (go) {
                try {
                    [{ user }] = [await api.console.users.delete(user)];

                    this.users = this.users.filter(({ _id }) => _id !== user._id);
                    alert(`회원 (ID: ${user?.username})가 삭제되었습니다.`);
                } catch (error) {
                    console.error(error);
                    alert(error.response ? error.response.data.email : error.email);
                }
            }
        },

        async updateItem(item) {
            const index = this.users.findIndex(({ _id }) => _id == item._id);
            if (index == -1) this.search();
            else this.users.splice(index, 1, item);
        },

        async excel() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { params } = this;
                var { users } = await api.console.users.gets({
                    params,
                });

                this.$excel(users, headers, "회원목록");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        display: flex;
        align-items: center;
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
