var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', {
    attrs: {
      "title": "장비사용"
    }
  }, [_c('autocomplete-rental-subject', _vm._b({
    attrs: {
      "label": "사용가능 장비",
      "params": {
        type: _vm.RENTAL_SUBJECT_TYPES.EQUIPMENTS.value
      },
      "headers": {
        sort: JSON.stringify({
          name: 1
        })
      },
      "multiple": "",
      "dense": "",
      "menu-props": {
        maxHeight: '90vh'
      }
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var parent = _ref.parent,
          item = _ref.item,
          attrs = _ref.attrs,
          itemText = _ref.itemText;
        return [_c('v-chip', _vm._b({
          key: item._id,
          attrs: {
            "outlined": "",
            "label": "",
            "close": ""
          },
          on: {
            "click:close": function ($event) {
              return parent.selectItem(item);
            }
          }
        }, 'v-chip', attrs, false), [_vm._v(_vm._s(itemText(item)))])];
      }
    }]),
    model: {
      value: _vm.form.blacksmith._equipments__rentable,
      callback: function ($$v) {
        _vm.$set(_vm.form.blacksmith, "_equipments__rentable", $$v);
      },
      expression: "form.blacksmith._equipments__rentable"
    }
  }, 'autocomplete-rental-subject', _vm.attrs_input__verticalTable, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }