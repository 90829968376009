<template>
    <v-card class="mt-6">
        <v-card-title class="subtitle-1">첨부파일</v-card-title>
        <v-card-text>
            <v-row align="center" class="row--small">
                <v-col cols="12" md="11" class="py-0">
                    <v-file-input v-model="temp" dense hide-details outlined />
                </v-col>
                <v-col cols="12" md="1" class="py-0">
                    <v-btn block outlined color="primary" @click="addFile">첨부</v-btn>
                </v-col>
            </v-row>
            <v-chip-group :value="value" multiple>
                <v-chip v-for="(file, i) in value" :key="i" :value="file" @click="removeFile(file)">
                    <span>{{ file.name }}</span>
                    <v-icon small>mdi-close</v-icon>
                </v-chip>
            </v-chip-group>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: ["value"],
    data() {
        return {
            temp: null,
        };
    },
    methods: {
        addFile() {
            if (this.temp) {
                this.$emit("input", [...this.value, this.temp]);
                this.temp = null;
            } else alert("파일을 추가하세요.");
        },
        removeFile(file) {
            console.log({ file });
            if (file) {
                this.$emit(
                    "input",
                    this.value.filter((item) => item !== file)
                );
            }
        },
    },
};
</script>

<style></style>
