<template>
    <v-dialog v-model="shows" max-width="880" persistent>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }" tile class="d-flex flex-column" max-height="90vh" style="overflow: hidden">
            <v-card-title>
                <template v-if="isCreate">공지사항 생성</template>
                <template v-else>공지사항 수정</template>
                <v-spacer />
                <v-btn absolute right text icon @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="py-6" style="flex: 0 1 100%; overflow-y: auto">
                <v-row class="ma-n2">
                    <v-col cols="12" md="8" class="pa-2">
                        <v-text-field v-model="form.subject" label="제목" v-bind="inputAttrs" />
                    </v-col>
                    <v-col cols="12" md="4" class="pa-2">
                        <v-datetime-field v-model="form.createdAt" label="생성일시" v-bind="inputAttrs" />
                    </v-col>
                    <v-col cols="12" class="pa-2">
                        <file-chips-field v-model="form.files" label="첨부파일" v-bind="inputAttrs" />
                    </v-col>
                    <v-col cols="12" class="pa-2">
                        <naver-smarteditor v-model="form.content" rows="10" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-col cols="auto" class="py-0" style="min-width: 140px">
                    <v-switch v-model="form.isNotice" v-bind="switchAttrs">
                        <template #label>
                            <span class="subtitle-2"> 상단고정 </span>
                        </template>
                    </v-switch>
                </v-col>
                <v-col cols="auto" class="py-0" style="min-width: 140px">
                    <v-switch v-model="form.shows" v-bind="switchAttrs">
                        <template #label>
                            <span class="subtitle-2">
                                <template v-if="form.shows">노출</template>
                                <template v-else>미노출</template>
                            </span>
                        </template>
                    </v-switch>
                </v-col>
                <v-spacer />
                <v-btn large color="primary" v-bind="{ loading }" @click="save"> 저장하기 </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { initCenterNotification, inputAttrs, switchAttrs } from "@/assets/variables";

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import FileChipsField from "@/components/console/dumb/file-chips-field.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        VDatetimeField,
        FileChipsField,
        NaverSmarteditor,
    },
    props: {
        value: { type: Object, default: () => initCenterNotification({ sites: ["blacksmith"] }) },
    },
    data: () => ({
        form: initCenterNotification({ sites: ["blacksmith"] }),

        inputAttrs,
        switchAttrs,

        shows: false,
        loading: false,
    }),
    computed: {
        _notification() {
            return this.value?._id;
        },
        isCreate() {
            return !this._notification;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (this.isCreate) {
                    this.form = initCenterNotification({ sites: ["blacksmith"] });
                } else {
                    let { notification } = await api.console.center.notifications.get({ _id: this._notification });

                    this.form = initCenterNotification(notification);
                }
            } finally {
                this.loading = false;
            }
        },

        async emit() {
            this.form = initCenterNotification(this.form);
        },

        validate() {},

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.validate();

                let { _files = [], files = [], ...form } = this.form;

                // POST/PUT notification
                let { post, put } = api.console.center.notifications;
                let { notification } = await (this.isCreate ? post(form) : put(form));

                const { _id: _notification } = notification;

                if (files.length) {
                    files = await Promise.all(files.map(async (file, index) => (file instanceof File ? (await api.console.common.files.post({ path: "center-notifications", _notification, index }, file))?.file : file)));
                }

                await Promise.all(_files.filter((_file) => !files.some((file) => file._id == _file)).map(async (file) => await api.console.common.files.delete({ _id: file._id })));

                _files = files.map(({ _id }) => _id);

                await put({ _id: _notification, _files });

                alert("저장되었습니다");
                this.$emit("input", notification);
                this.shows = false;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.v-input.text-align-right {
    ::v-deep input {
        text-align: right;
    }
}
</style>