var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v(_vm._s(_vm.title))]);

}
var staticRenderFns = []

export { render, staticRenderFns }