var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, [_vm._l(_vm.files, function (file) {
    return _c('v-chip', {
      key: `${file.name}`,
      attrs: {
        "small": "",
        "close": ""
      },
      on: {
        "click:close": function ($event) {
          return _vm.remove(file);
        }
      }
    }, [_vm._v(_vm._s(file === null || file === void 0 ? void 0 : file.name))]);
  }), _c('v-fade-transition', [_c('v-file-btn', _vm._g({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.pushable,
      expression: "pushable"
    }],
    staticStyle: {
      "align-self": "center"
    },
    attrs: {
      "text": "",
      "icon": "",
      "small": ""
    }
  }, {
    change: _vm.change
  }), [_c('v-icon', [_vm._v(" mdi-plus-circle ")])], 1)], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }