<template>
    <v-chip-group column>
        <v-chip v-for="file in files" :key="`${file.name}`" small close @click:close="remove(file)">{{ file?.name }}</v-chip>
        <v-fade-transition>
            <v-file-btn v-show="pushable" text icon small v-on="{ change }" style="align-self: center">
                <v-icon> mdi-plus-circle </v-icon>
            </v-file-btn>
        </v-fade-transition>
    </v-chip-group>
</template>

<script>
import VFileBtn from "@/components/plugins/vuetify/v-file-btn.vue";

export default {
    components: {
        VFileBtn,
    },
    props: {
        value: { type: Array, default: () => [] },
        maxLength: { type: [Number, String], default: 5 },
    },
    data: () => ({
        files: [],
    }),
    computed: {
        pushable() {
            if (this.maxLength) return this.files?.length < this.maxLength;
            else return true;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        files() {
            this.emit();
        },
    },
    methods: {
        sync() {
            this.files = this.value || [];
        },
        emit() {
            this.$emit("input", this.files);
        },
        remove(file) {
            this.files = this.files.filter((item) => item != file);
        },
        change(file) {
            this.files = [...this.files, file].map((item) => item);
        },
    },
};
</script>
