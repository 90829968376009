var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', {
    attrs: {
      "title": "시설사용",
      "hideDivider": ""
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          staticClass: "mr-n3",
          on: {
            "change": _vm.emit
          },
          model: {
            value: _vm.form.blacksmith.facility.isActive,
            callback: function ($$v) {
              _vm.$set(_vm.form.blacksmith.facility, "isActive", $$v);
            },
            expression: "form.blacksmith.facility.isActive"
          }
        }, 'v-switch', _vm.switchAttrs, false))];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }