var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', _vm._b({
    staticClass: "file-chips-field",
    attrs: {
      "readonly": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('file-chips-input', {
          on: {
            "input": _vm.emit
          },
          model: {
            value: _vm.files,
            callback: function ($$v) {
              _vm.files = $$v;
            },
            expression: "files"
          }
        })];
      },
      proxy: true
    }])
  }, 'v-text-field', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }